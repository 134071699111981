<template>
	<div class="transition-cubic fill-height">
		<div v-if="edit" class="rounded-lg overflow-hidden fill-height">
			<div v-if="new_val" class="fill-height">
				<v-img :src="backend_url + new_src" height="100%" contain class="cell">
					<div style="height: 100%; flex-direction: column" class="d-flex pa-2">
						<v-row class="d-flex mx-0" style="flex-grow: 1" dense
						       v-if="new_src && typeof new_val !== 'object'">
							<v-col cols="auto">
								<Button iconVal="mdi-pencil" :toolTip="$tc('single_trad.ItemImage.edit', 1)" large
								        :click="() => browseFile()" color="primary"/>
							</v-col>
							<v-col cols="cols">
								<Button iconVal="mdi-delete" :toolTip="$tc('single_trad.ItemImage.delete', 1)" large
								        :click="() => confirm_dialog = true" color="error" :loading="loading"/>
							</v-col>
						</v-row>
						<v-row class="d-flex mx-0"
						       style="flex-grow: 1; justify-content: flex-end; align-items: flex-end" dense
						       v-if="new_src && typeof new_val === 'object'">
							<v-col cols="auto">
								<Button iconVal="mdi-close" iconPosition="center"
								        :toolTip="$tc('global.action.cancel', 1)" large :click="() => removeImage()"
								        color="error"/>
							</v-col>
							<v-col cols="auto" v-if="!creation">
								<Button iconVal="mdi-check" :toolTip="$tc('single_trad.ItemImage.save', 1)" large
								        :click="() => saveImage()" color="success" :loading="loading"/>
							</v-col>
						</v-row>
					</div>
				</v-img>
			</div>
			<div v-else class="fill-height">
				<div class="text-center cell3 fill-height d-flex pa-3"
				     style="flex-direction: column; justify-content: center"
				>
					<v-icon color="cell4" size="40">mdi-image-off-outline</v-icon>
					<div class="cell4--text title-2 font-weight-bold">{{ $t('single_trad.ImagePlaceholder.no_image')}}</div>
					<div class="mt-6">
						<Button :label="$tc('single_trad.ImagePlaceholder.add_image', 1)" color="primary"
						        :click="() => browseFile()"/>
					</div>
				</div>
			</div>
			<input
					type="file"
					accept="image/*"
					hidden
					ref="file_upload"
					@change="(e) => preloadPicture(e)"
			/>
		</div>
		<div v-else class="fill-height" ref="parent">
			<div v-if="new_val">
				<v-carousel :height="parent_height" hide-delimiter-background hide-delimiters :show-arrows="new_val.length > 1">
					<v-carousel-item
							v-for="(item,i) in new_val"
							:key="i"
							:src="$store.getters['config/backend_url'] + item.__url__"
							reverse-transition="fade-transition"
							transition="fade-transition"
					/>
				</v-carousel>
			</div>
			<div v-else class="fill-height">
				<div
						class="text-center cell3 fill-height d-flex pa-3"
				        style="flex-direction: column; justify-content: center; align-content: center;"
				>
					<v-icon color="cell4" size="40">mdi-image-off-outline</v-icon>
					<div class="cell4--text title-2 font-weight-bold text-truncate">{{
						$t('single_trad.ImagePlaceholder.no_image') }}
					</div>
				</div>
			</div>

		</div>

		<Dialog v-model="confirm_dialog" type="confirm" :message="$tc('single_trad.ItemImage.dialog_v', 1)"
		        @cancel="confirm_dialog = false" @confirm="deleteImage()" :loading="loading"/>
	</div>
</template>

<script>
	export default {
		name: "ImageField",
		props: ['value', 'edit', 'creation', 'path', 'fieldName', 'src', 'ratio'],
		components: {
			Dialog: () => import('@/components/ui/Dialog.vue'),
			Button: () => import('@/components/ui/Button.vue')
		},
		data() {
			return {
				new_src: null,
				loading: false,
				confirm_dialog: false,
				parent_height: 0
			}
		},
		computed: {
			backend_url() {
				return this.$store.getters['config/backend_url']
			},
			new_val: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				}
			}
		},
		methods: {
			browseFile() {
				this.$refs['file_upload'].click()
			},
			preloadPicture(e) {
				const maxSize = 2000000

				if (e.target.files.length) {
					//if (e.target.files[0].size < maxSize) {
					const files = e.target.files[0]
					this.new_val = files
					this.new_src = URL.createObjectURL(files)
					//}
				}
			},
			removeImage() {
				this.new_src = null
				this.new_val = null
			},
			deleteImage() {
				this.loading = true

				let data = {}

				data[this.fieldName] = null

				this.$wsc.patchItem(this.path, this.$route.params[this.$route.name], data, succ => {
					this.confirm_dialog = false
					this.new_src = succ[this.fieldName]
					this.new_val = succ[this.fieldName]
					this.loading = false
					this.$store.dispatch('snackbar/success', this.$t('snackbar.ItemImage.deleteImage.success'))
				}, fai => {
					this.loading = false
					this.$store.dispatch('snackbar/error', this.$t('snackbar.ItemImage.deleteImage.fail'))
				})
			},
			saveImage() {
				this.loading = true
				let data = {}

				data[this.fieldName] = this.new_val

				this.$wsc.toFormData(data, (success, header) => {
					this.$wsc.patchItem(this.path, this.$route.params[this.$route.name], success, succ => {
						this.new_src = succ[this.fieldName]
						this.new_val = succ[this.fieldName]
						this.loading = false
					}, fai => {
						this.loading = false
					}, header)
				})
			},
			computeShape(e){
				this.parent_height = (!this.ratio ? e[0].target.clientWidth * 10/16 : e[0].target.clientWidth)
			}
		},
		mounted() {
			this.new_src = this.value
			this.$emit('ready')
			new ResizeObserver(this.computeShape).observe(this.$refs.parent)
		}
	}
</script>

<style scoped>